export type StorageType = "local" | "session";

export function getWindowStorage(type: StorageType): Storage | null {
  if (typeof window === "undefined") {
    return null;
  }

  switch (type) {
    case "local":
      return window.localStorage;
    case "session":
      return window.sessionStorage;
    default:
      return null;
  }
}
